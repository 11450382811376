import { Route, HashRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import InterviewHistory from "./components/interview_history/InterviewHistory";
import { InterviewChatPage } from "./components/interview_lobby/InterviewChat";
import InterviewLobby from "./components/interview_lobby/InterviewLobby";
import Login from "./components/login/Login";
import UserHome from "./components/user_home/UserHome";
import UserProfile from "./components/user_profile/UserProfile";
import ProtectedRoute from "./utils/security/ProtectedRoute";
import posthog from 'posthog-js'

posthog.init('phc_zDtbHuhbkVvBOvq0Xqy7vlGOVHfnHnrKKYRciooT6xv',
    {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
    }
)

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ProtectedRoute element={UserHome} />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/interview"
          element={<ProtectedRoute element={InterviewLobby} />}
        />
        <Route
          path="/interview/:id"
          element={<ProtectedRoute element={InterviewChatPage} />}
        />
        <Route
          path="/profile"
          element={<ProtectedRoute element={UserProfile} />}
        />
        <Route
          path="/history"
          element={<ProtectedRoute element={InterviewHistory} />}
        />
      </Routes>
    </Router>
  );
}

export default App;
