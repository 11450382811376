import { AxiosError } from "axios";
import { LoggedUser } from "../types/LoggedUser";
import { BaseApi } from "./HttpServices";

class AuthorizationService extends BaseApi {
  async login(username: string, password: string): Promise<boolean> {
    try {
      const response = await this.api.post("/auth/login", {
        username,
        password,
      });
      return true;
    } catch (error) {
      const response = (error as AxiosError).response;
      if (response?.status === 401) {
        throw new Error("Invalid username or password");
      }
      console.error("Login failed", response?.data);
      throw error;
    }
  }

  async isLogged(): Promise<LoggedUser> {
    try {
      const response = await this.api.get("/user/");
      return response.data.data;
    } catch (error) {
      console.error("Error checking verification", error);
      throw error;
    }
  }
}

const AuthService = new AuthorizationService();
export default AuthService;
