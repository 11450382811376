import {useEffect, useRef, useState} from "react";
import {Params, useParams} from "react-router-dom";
import {InterviewQuestionService} from "../../services/InterviewQuestionServices";
import {InterviewService} from "../../services/InterviewServices";
import {ChatMessage} from "../../types/ChatMessage";
import {InterviewState} from "../../types/InterviewTypes";
import {AnimatedAvatar, AnimatedIcon, AvatarList, BotAvatarList,} from "../interview_components/AnimatedAvatar";
import {RecordingTag} from "../interview_components/RecordingTag";
import {CenterRippleLoading} from "../loading/CenterRippleLoading";
import {TextToSpeechComp} from "../text_to_speech/TextToSpeechSimple";
import {VoiceRecorderComp} from "../voice_recorder/VoiceRecorder";
import "./InterviewChat.css";

export const ChatPage = ({
                             messages,
                             isRecording,
                             isThinking,
                         }: {
    messages: ChatMessage[];
    isRecording: boolean;
    isThinking: boolean;
}) => {
    const messagesEndRef = useRef<null | HTMLDivElement>(null);
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({behavior: "smooth"});
    };

    const [hideTexts, setHideTexts] = useState(false);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHideTexts(event.target.checked);
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <div
            id="background"
            className="container-fluid text-center chat-background"
        >
            <div
                id="chatContainer"
                className="row align-items-end"
                style={{height: "100%"}}
            >
                <div id="userAvatar" className="col-2">
                    <CheckboxWithCallback
                        hideTexts={hideTexts}
                        onChangeCallback={handleCheckboxChange}
                    />
                    <div>
                        <RecordingTag recording={isRecording}/>
                        <br/>
                        <AnimatedAvatar imageSrc={AvatarList[2]}/>
                    </div>
                </div>
                <div id="chat-container" className="col-8">
                    {messages
                        ? messages.map((msg, index) => (
                            <ChatMessageBox
                                key={index}
                                message={msg.message}
                                owner={msg.owner}
                                blur={hideTexts}
                            />
                        ))
                        : null}
                    <div ref={messagesEndRef}/>
                </div>
                <div id="botAvatar" className="col-2">
                    {isThinking ? <AnimatedIcon iconKey={"working"}/> : ""}
                    <br/>
                    <AnimatedAvatar imageSrc={BotAvatarList[0]}/>
                </div>
            </div>
        </div>
    );
};

const ChatMessageBox = (props: {
    message: string;
    owner: string;
    blur: boolean;
}) => {
    const blurClass = props.blur ? "blur-msg" : "";
    switch (props.owner) {
        case "user":
            return (
                <div className="row">
                    <div className={`col-md-auto owner chat-msg-text ${blurClass}`}>
                        {props.message}
                    </div>
                    <div className="col"></div>
                </div>
            );
        case "bot":
            return (
                <div className="row">
                    <div className="col"></div>
                    <div className={`col-md-auto chat-msg-text ${blurClass}`}>
                        {props.message}
                    </div>
                </div>
            );
        case "system":
            return (
                <div className="row">
                    <div className="col"></div>
                    <div className="col-md-auto system chat-msg-text">
                        {props.message}
                    </div>
                    <div className="col"></div>
                </div>
            );
        default:
            return (
                <div className="row">
                    <div className="col"> Something went wrong</div>
                </div>
            );
    }
};

function getAndParseParams(params: Readonly<Params<string>>) {
    if (!params.id) throw new Error("No id in params");
    return {interviewId: Number(params.id)};
}

type InterviewStates = "loading" | "ready" | "running" | "finished";

export const InterviewChatPage = () => {
    const {interviewId} = getAndParseParams(useParams());
    const userToken = localStorage.getItem("loggedUser")!!;
    const interviewService = new InterviewService({
        token: userToken,
    });
    const questionService = new InterviewQuestionService({
        interviewId,
        token: userToken,
    });

    const [messages, setMessages] = useState<ChatMessage[]>([]);

    const [textToRead, setTextToRead] = useState("");
    const [transcriptText, setTranscriptText] = useState("");
    const [interviewPageState, setInterviewPageState] =
        useState<InterviewStates>("loading");

    const [isRecording, setIsRecording] = useState(false);
    const [isEvaluating, setIsEvaluating] = useState(false);
    const [isThinking, setIsThinking] = useState(false);
    const [recordingToken, setRecordingToken] = useState<string | null>(null);
    const [fetchingAnswer, setFetchingAnswer] = useState(false);

    const [activeQuestionIndex, setActiveQuestionIndex] = useState(-1);
    // Interview State
    const [interviewState, setInterviewState] = useState<InterviewState>({
        status: "",
        questionsId: [],
    });

    const getInterviewInfo = async () => {
        try {
            const data = await interviewService.GetInterviewInfo(interviewId);
            setInterviewState({
                questionsId: data.questions.map((q) => q.id),
                status: data.status,
            });
            setInterviewPageState("ready");
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (!transcriptText || transcriptText.length === 0) return;
        updateLastMessage(transcriptText);
    }, [transcriptText]);

    useEffect(() => {
        if (interviewState.status !== "") return;
        getInterviewInfo();
    }, []);

    useEffect(() => {
        if (activeQuestionIndex < 0) return;
        else if (activeQuestionIndex >= interviewState.questionsId.length) {
            setIsThinking(true);
            interviewService.FinishInterview(interviewId).then((data) => {
                setInterviewPageState("finished");
            });
            return;
        }
        setIsThinking(true);
        const questionId = interviewState.questionsId[activeQuestionIndex];
        questionService.GetInterviewQuestion(questionId).then((question) => {
            setIsThinking(false);
            setTextToRead(question.question);
        });
    }, [activeQuestionIndex]);

    useEffect(() => {
        if (!fetchingAnswer) return;
        fetchAnswerForQuestions();
    }, [fetchingAnswer]);

    const fetchAnswerForQuestions = async () => {
        let retries = 5;
        while (retries-- > 0) {
            try {
                await new Promise((resolve) => setTimeout(resolve, 1000));
                const answer = await questionService.GetInterviewQuestionResult(
                    interviewState.questionsId[activeQuestionIndex]
                );
                setIsEvaluating(true);
                setFetchingAnswer(false);
                setIsThinking(false);
                setTextToRead(answer.evaluation);
                return;
            } catch (e) {
                console.log("Retrying...", e);
            }
            console.log("Failed to fetch question answer...");
        }
    };

    const utteranceStartCallback = (message: string) => {
        console.log("Utterance Started");
        setMessages(messages.concat({owner: "bot", message: message}));
    };

    const utteranceFinishCallback = async () => {
        if (isEvaluating) {
            setIsEvaluating(false);
            setActiveQuestionIndex(activeQuestionIndex + 1);
        } else {
            setIsThinking(true);
            const question = await questionService.StartInterviewQuestion(
                interviewState.questionsId[activeQuestionIndex]
            );
            console.log("Question Started: ", question);
            setRecordingToken(question.token);
        }
    };

    const updateLastMessage = (newText: string) => {
        const newMessages = messages.map((m, i) => {
            if (i === messages.length - 1) {
                m.message = newText;
            }
            return m;
        });
        setMessages(newMessages);
    };

    const voiceRecorderCompStartCallback = () => {
        setIsThinking(false);
        setIsRecording(true);
        setMessages(messages.concat({owner: "user", message: ""}));
    };

    const voiceRecorderCompEndCallback = async () => {
        setIsRecording(false);
        setRecordingToken(null);
        setIsThinking(true);
        const data = await questionService.FinishInterviewQuestion(
            interviewState.questionsId[activeQuestionIndex],
            transcriptText
        );
        setFetchingAnswer(true);
    };

    const startInterviewCallback = async () => {
        if (interviewState.status === "ready")
            await interviewService.StartInterview(interviewId);
        setActiveQuestionIndex(0);
        setInterviewPageState("running");
    };

    switch (interviewPageState) {
        case "loading":
            return CenterRippleLoading("Loading interview...");
        case "ready":
            return (
                <InterviewStartPrompt startInterviewCallback={startInterviewCallback}/>
            );
        case "finished":
            return <div>Interview Finished</div>;
        case "running":
            return (
                <div>
                    <TextToSpeechComp
                        utteranceStartCallback={utteranceStartCallback}
                        utteranceFinishCallback={utteranceFinishCallback}
                        text={textToRead}
                    />
                    <VoiceRecorderComp
                        token={recordingToken}
                        startCallback={voiceRecorderCompStartCallback}
                        textUpdateCallback={setTranscriptText}
                        endCallback={voiceRecorderCompEndCallback}
                    />
                    <ChatPage
                        messages={messages}
                        isRecording={isRecording}
                        isThinking={isThinking}
                    />
                </div>
            );
    }
};

const CheckboxWithCallback = ({
                                  hideTexts,
                                  onChangeCallback,
                              }: {
    hideTexts: boolean;
    onChangeCallback: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
    return (
        <div className="form-check form-switch">
            <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                checked={hideTexts}
                onChange={onChangeCallback}
            />
            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                Hide texts
            </label>
        </div>
    );
};

const InterviewStartPrompt = ({
                                  startInterviewCallback,
                              }: {
    startInterviewCallback: () => void;
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [hasPermissions, setHasPermissions] = useState(false);

    useEffect(() => checkPermissions(), []);

    function checkPermissions() {
        /* TODO improve how permissions are being handled */
        const permissions = navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false,
        });
        permissions
            .then((stream) => {
                setHasPermissions(true);
            })
            .catch((err) => {
                setHasPermissions(false);
                console.log(`${err.name} : ${err.message}`);
            });
    }

    const startInterview = () => {
        setIsLoading(true);
        startInterviewCallback();
    };

    if (isLoading) return CenterRippleLoading("Starting interview...");
    return (
        <div
            style={{
                flexFlow: "column",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}
        >
            <h1>Interview is ready</h1>
            {hasPermissions ? (
                <button onClick={() => startInterview()}>Start Interview</button>
            ) : (
                <p>Need microphone permissions</p>
            )}
        </div>
    );
};
