import {BaseApi} from "./HttpServices";

type InterviewQuestionGET = {
  question: string;
  status: string;
  is_valid?: boolean;
  evaluation?: string;
  answer?: string;
  started_at?: string;
  finished_at?: string;
};

type InterviewQuestionPOST = {
  question: string;
  status: string;
  started_at: string;
  token: string;
};

type InterviewQuestionResultGET = {
  is_valid: boolean;
  evaluation: string;
};

export class InterviewQuestionService extends BaseApi {
  interviewId: number;
  token: string;

  constructor({ interviewId, token }: { interviewId: number; token: string }) {
    super();
    this.interviewId = interviewId;
    this.token = token;
  }

  async GetInterviewQuestion(
    questionId: number
  ): Promise<InterviewQuestionGET> {
    try {
      const response = await this.api.get(
        `/interview/${this.interviewId}/${questionId}`,
      );
      return response.data.data;
    } catch (error) {
      console.error("Error getting the question", error);
      throw error;
    }
  }

  async StartInterviewQuestion(
    questionId: number
  ): Promise<InterviewQuestionPOST> {
    try {
      const response = await this.api.post(
        `/interview/${this.interviewId}/${questionId}`,
        undefined,
      );
      return response.data.data;
    } catch (error) {
      console.error("Error starting the question", error);
      throw error;
    }
  }

  async FinishInterviewQuestion(
    questionId: number,
    answer: string
  ): Promise<boolean> {
    try {
      await this.api.patch(
        `/interview/${this.interviewId}/${questionId}`,
        { answer },
      );
      return true;
    } catch (error) {
      console.error("Error finishing the questions", error);
      throw error;
    }
  }

  async GetInterviewQuestionResult(
    questionId: number
  ): Promise<InterviewQuestionResultGET> {
    try {
      const response = await this.api.get(
        `/interview/${this.interviewId}/${questionId}/result`,
      );
      return response.data.data;
    } catch (error) {
      console.error("Error getting the questions results", error);
      throw error;
    }
  }
}
