import { useEffect, useMemo, useState } from "react";

export const TextToSpeechComp = ({
  utteranceStartCallback,
  utteranceFinishCallback,
  text,
}: {
  utteranceStartCallback: (message: string) => void;
  utteranceFinishCallback: () => void;
  text: string;
}) => {
  const [isPaused, setIsPaused] = useState(false);
  const [utterance, setUtterance] = useState<SpeechSynthesisUtterance>();
  const [readingText, setReadingText] = useState("");
  const synth = window.speechSynthesis;
  const voices = synth.getVoices();
  const voice = useMemo(() => voices.find((v) => v.lang === "en-US"), [voices]);

  if (text && text !== readingText) setReadingText(text);

  useEffect(() => {
    if (readingText && readingText.length > 0) {
      synth.cancel();
      const u = createUtterance(readingText, voice!);
      utteranceStartCallback(readingText);
      setUtterance(u);
    }
  }, [readingText]);

  useEffect(() => {
    if (!utterance) return;
    if (isPaused) {
      synth.resume();
    } else {
      queueMicrotask(() => {
        synth.speak(utterance);
        let r = setInterval(() => {
          console.log(synth.speaking);
          if (!synth.speaking) {
            clearInterval(r);
          } else {
            synth.pause();
            synth.resume();
          }
        }, 14000);
      });
      utterance.addEventListener("end", () =>
        utteranceFinishCallback ? utteranceFinishCallback() : null
      );
    }
    setIsPaused(false);
  }, [utterance]);

  useEffect(() => {
    console.log("Reading Text: ", readingText);
  }, [readingText]);

  useEffect(() => {
    return () => synth.cancel();
  }, []);

  return <div></div>;
};

function createUtterance(readingText: string, voice: SpeechSynthesisVoice) {
  const u = new SpeechSynthesisUtterance(readingText);
  u.lang = "en-US";
  u.voice = voice!;
  u.pitch = 1;
  u.rate = 1;
  u.volume = 1;
  return u;
}
