import {
    InterviewFinishPATCH,
    InterviewGET,
    InterviewHistoryGET,
    InterviewPOST,
    InterviewStartPOST,
} from "../types/InterviewService";
import {BaseApi} from "./HttpServices";

export class InterviewService extends BaseApi {
    token: string;

    constructor({token}: { token: string }) {
        super();

        this.token = token;
    }

    async CreateInterview(
        jobTitle: string,
        jobDescription: string
    ): Promise<InterviewPOST> {
        try {
            const response = await this.api.post(
                "/interview/",
                {
                    job_description: jobDescription,
                    job_title: jobTitle,
                },
            );
            return response.data.data;
        } catch (error) {
            console.error("Error creating the interview", error); // TODO fix error messages
            throw error;
        }
    }

    async GetInterviewsHistory(
        page: number = 0
    ): Promise<Array<InterviewHistoryGET>> {
        try {
            const response = await this.api.get(`/interview/`, {
                params: {
                    page,
                },
            });
            return response.data.data;
        } catch (error) {
            console.error("Error getting interview history", error);
            throw error;
        }
    }

    async GetInterviewInfo(interviewId: number, retry: number = 0, max_attempts: number = 5, wait: number = 2): Promise<InterviewGET> {
        const response = await this.api.get(`/interview/${interviewId}`);
        switch (response.status) {
            case 200:
                return response.data.data;
            case 204:
                if (retry < max_attempts) {
                    console.log(`Retrying in ${wait} seconds`);
                    await new Promise((resolve) => setTimeout(resolve, wait * 1000));
                    return await this.GetInterviewInfo(interviewId, retry + 1, max_attempts, wait);
                }
                console.error("Error getting interview data");
                throw new Error("Max attempts reached");
            default:
                console.error("Error getting interview data from the server");
                throw new Error("Server error fetching the interview data");

        }

    }

    async StartInterview(interviewId: number): Promise<InterviewStartPOST> {
        try {
            const response = await this.api.post(
                `/interview/${interviewId}`,
                undefined,
            );
            return response.data.data;
        } catch (error) {
            console.error("Error fetching the interview data", error);
            throw error;
        }
    }

    async FinishInterview(interviewId: number): Promise<InterviewFinishPATCH> {
        try {
            const response = await this.api.patch(
                `/interview/${interviewId}`,
                undefined,
            );
            return response.data.data;
        } catch (error) {
            console.error("Error finishing the interview", error);
            throw error;
        }
    }
}
