import {ElementType, useEffect, useState} from "react";
import {Navigate} from "react-router-dom";
import AuthService from "../../services/AuthServices";
import {LoggedUser} from "../../types/LoggedUser";

function ProtectedRoute({
                            element: Element,
                            ...rest
                        }: {
    element: ElementType;
}) {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
    const [loggedUser, setLoggedUser] = useState<LoggedUser | null>(null);

    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const userData = await AuthService.isLogged();
                setIsAuthenticated(true);
                setLoggedUser(userData);
            } catch (error) {
                console.error("Error during authentication", error);
                setIsAuthenticated(false);
            }
        };
        checkAuthentication().then(r => null);
    }, []);

    if (isAuthenticated === null) {
        return null;
    }

    return isAuthenticated ? (
        <Element {...loggedUser} />
    ) : (
        <Navigate to="/login" replace/>
    );
}

export default ProtectedRoute;
