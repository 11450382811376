import { MutableRefObject, useEffect, useRef, useState } from "react";
import RecordRTC from "recordrtc";
import "../../App.css";

const AAI_WS_URL =
  "wss://api.assemblyai.com/v2/realtime/ws?sample_rate=16000&token=";

export const VoiceRecorderComp = ({
  token,
  startCallback,
  endCallback,
  textUpdateCallback,
}: {
  token: string | null;
  startCallback: () => void;
  endCallback: () => void;
  textUpdateCallback?: (text: string) => void;
}) => {
  const socket = useRef(null) as MutableRefObject<WebSocket | null>;
  const recorder = useRef(null) as MutableRefObject<RecordRTC | null>;
  const [isRecording, setIsRecording] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  let lastTranscript = "";
  let lastUpdated = Date.now();

  useEffect(() => {
    if (isCompleted) {
      endCallback();
      setIsCompleted(false);
      setIsRecording(false);
      lastTranscript = "";
    }
  }, [isCompleted]);

  useEffect(() => {
    if (token && !isRecording) {
      generateTranscript(token);
    }
  }, [token, isRecording]);

  const endTranscriptionRecording = async () => {
    try {
      if (socket.current) {
        socket.current.send(JSON.stringify({ terminate_session: true }));
        socket.current.close();
      }
      console.log(prompt);
      socket.current = null;

      if (recorder.current) recorder.current.stopRecording();
      recorder.current = null;
    } catch (error) {
      console.error(error);
      if (socket.current) socket.current.close();
      return;
    }
    setIsCompleted(true);
  };

  const generateTranscript = async (token: string) => {
    socket.current = new WebSocket(AAI_WS_URL + token);

    const texts = {} as { [key: number]: string };
    socket.current.onmessage = (voicePrompt) => {
      let msg = "";
      const res = JSON.parse(voicePrompt.data) as {
        audio_start: number;
        text: string;
      };
      texts[res.audio_start] = res.text;
      const keys = Object.keys(texts) as unknown as number[];
      keys.sort((a, b) => a - b);
      for (const key of keys) {
        if (texts[key]) {
          msg += ` ${texts[key]}`;
          console.log(msg);
        }
      }
      if (msg !== lastTranscript) {
        lastTranscript = msg;
        lastUpdated = Date.now();
        textUpdateCallback && textUpdateCallback(msg);
      } else {
        const now = Date.now();
        const timeDifference = (now - lastUpdated) / 1000;
        if (timeDifference > 5) {
          console.log("No new message in the last 5 seconds.");
          endTranscriptionRecording();
        }
      }
    };

    socket.current.onerror = (event) => {
      console.error(event);
      if (socket.current) socket.current.close();
    };

    socket.current.onclose = (event) => {
      console.log("Closing socket connection!");
      recorder.current && recorder.current.stopRecording();
      socket.current = null;

    };

    socket.current.onopen = () => {
      console.log("Connection Open!");
      lastUpdated = Date.now();
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          recorder.current = new RecordRTC(stream, {
            type: "audio",
            mimeType: "audio/webm;codecs=pcm",
            recorderType: RecordRTC.StereoAudioRecorder,
            timeSlice: 250,
            desiredSampRate: 16000,
            numberOfAudioChannels: 1,
            bufferSize: 4096,
            audioBitsPerSecond: 128000,
            ondataavailable: (blob) => {
              const reader = new FileReader();
              reader.onload = () => {
                const base64data = reader.result as string;
                if (socket.current && base64data) {
                  socket.current.send(
                    JSON.stringify({
                      audio_data: base64data.split("base64,")[1],
                      end_utterance_silence_threshold: 1500,
                    })
                  );
                }
              };
              reader.readAsDataURL(blob);
            },
          });
          startCallback();
          setIsRecording(true);
          recorder.current.startRecording();
        })
        .catch((err) => {
          recorder.current && recorder.current.stopRecording();
          console.error(err)
        });
    };
  };

  return <div></div>;
};
