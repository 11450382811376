import axios, { AxiosInstance } from "axios";

const getBaseUrl = (): string => {
  switch (process.env.NODE_ENV) {
    case "production":
      return "https://coachgpt-2utdk5kboa-rj.a.run.app";
    case "development":
      return "http://localhost:8000";
    default:
      return "https://coachgpt-2utdk5kboa-rj.a.run.app";
  }
};

class BaseApi {
  api: AxiosInstance;

  constructor() {
    this.api = axios.create({
      baseURL: getBaseUrl(),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      withCredentials: true,
      maxRedirects: 0,
      validateStatus: (status: number) => status >= 200 && status < 400,
    });
  }
}

export { BaseApi, getBaseUrl };
